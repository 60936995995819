/* eslint-disable */
import React, { useEffect, useState } from "react";
import ReactSelect, { type DropdownIndicatorProps, components, createFilter } from "react-select";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Placeholder from "image/placeholder.jpg"

export const Checkbox = ({ children, ...props }) => (
    <label style={{ marginRight: "1em" }}>
        <input type="checkbox" {...props} />
        {children}
    </label>
);

const Select = ({
    className = "",
    disabled = false,
    clearable = true,
    searchable = false,
    multiple = false,
    value = null,
    onChange,
    onInputChange,
    options = [],
    fillArrow = false,
    labelEntity = false,
    avatarEntity50 = false,
    avatarEntity14 = false,
    removeBorder = false,
    hideIndicator = false,
    avatarEntity14Style,
    avatarEntity14ValueContainerStyle,
    ...rest
}) => {
    const { t } = useTranslation();
    const [ignoreCase, setIgnoreCase] = useState(true);
    const [ignoreAccents, setIgnoreAccents] = useState(true);
    const [trim, setTrim] = useState(true);
    const [matchFromStart, setMatchFromStart] = useState(true);

    const handleChange = (value, evt) => {
        if (evt?.action === "select-option" || evt?.action === "remove-value") {
            if (value?.constructor === Array) {
                const values = value.map((option) => option.value);
                onChange?.(values, evt, value);
            } else {
                if (value) {
                    const id = value.value;
                    onChange?.(id, evt, value);
                } else {
                    onChange?.(multiple ? [] : null, evt, value);
                }
            }
        } else if (evt?.action === "clear") {
            onChange?.(null, evt, null);
        }
    };

    const handleInputChange = (inputValue, actionMeta) => {
        onInputChange?.(inputValue);
    };

    const getIsSelected = (option) => {
        if (value) {
            if (value.constructor === Array) {
                return value.includes(option.value);
            } else if (typeof value === "number") {
                return value === option.value;
            } else if (typeof value === "string") {
                return value === option.value;
            } else {
                return false;
            }
        }
        return false;
    };

    const getValue = () => {
        if (!value) {
            return null;
        }
        if (multiple) {
            const array = [];
            for (const option of options) {
                if (value.includes(option.value)) {
                    array.push(option);
                }
            }
            return array;
        } else {
            const option = options.find((opt) => opt.value === value);
            if (option) {
                return option;
            }
            return null;
        }
    };

    const DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <ArrowDropDownIcon />
            </components.DropdownIndicator>
        );
    };

    const filterConfig = {
        matchFrom: 'start',
    };

    return (
        <>
            <ReactSelect
                styles={
                    avatarEntity50
                        ?
                            {
                                option: (base) => ({
                                    ...base,
                                    position: 'relative',
                                    background: '#3c358f',
                                    left: 0,
                                }),
                            }
                        :
                    avatarEntity14
                        ?
                            {
                                control: base => ({
                                    ...base,
                                    height: 30,
                                    minHeight: 30,
                                    fontSize: 12,
                                    padding: 3,
                                    background: '#F3F4F6',
                                    border: 'unset',
                                    ...avatarEntity14Style
                                }),
                                valueContainer: (base) => ({
                                    ...base,
                                    ...avatarEntity14ValueContainerStyle
                                }),
                                dropdownIndicator: base => ({
                                    ...base,
                                    padding: 0,
                                    '& svg': {
                                        height: '1rem',
                                    }
                                })
                            }
                        :
                    removeBorder
                        ?
                            {
                                control: base => ({
                                    ...base,
                                    height: 30,
                                    minHeight: 30,
                                    fontSize: 12,
                                    padding: 3,
                                    background: 'white',
                                    border: 'unset',
                                    boxShadow: 'none',
                                    color: '#000',
                                    fontSize: 14
                                }),
                                valueContainer: (base) => ({
                                    ...base,
                                }),
                                indicatorSeparator: (base) => ({
                                    ...base,
                                    width: 0
                                }),
                                dropdownIndicator: base => ({
                                    ...base,
                                    padding: 0,
                                    '& svg': {
                                        height: '1rem',
                                    }
                                })
                            }
                        :
                            {}
                }
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 8,
                    height: 30,
                    padding: '6px 12px'
                })}
                className={`eschool-select ${className}`}
                isDisabled={disabled}
                isClearable={clearable}
                ignoreCase={true}
                ignoreAccents={true}
                trim={true}
                isSearchable={searchable}
                isMulti={multiple}
                value={getValue()}
                onChange={handleChange}
                onInputChange={handleInputChange}
                options={options}
                getOptionLabel={(option) => {
                    if (labelEntity) {
                        return <div dangerouslySetInnerHTML={{ __html: option.text }} />
                    }
                    if (avatarEntity50) {
                        return <div >
                            <img 
                                className="select-avatar-style mr-3" 
                                src={option?.avatar ? option?.avatar : Placeholder} 
                                width={50} 
                                height={50} 
                                onError={(e) => {
                                    e.target.onError = null,
                                        e.target.src = Placeholder
                                }}
                            /> 
                            <ul>
                                <li>{option.text}</li>
                            </ul>
                            <div className="d-inline-block select-avatar-text-style"></div>
                        </div>
                    }
                    if (avatarEntity14) {
                        if(option?.isSvg){
                            if(option?.avatar){
                                return (
                                    <div className='d-flex'>
                                        <div className="d-flex align-items-center select-avatar-style-14 mr-2">{option.avatar}</div>
                                        <div className="d-inline-block select-avatar-text-style-14">{option.text}</div>
                                    </div>
                                )
                            }
                        
                            return (
                                <div className='d-flex'>
                                    <img className="d-flex align-items-center select-avatar-style-14 mr-2" src={Placeholder} width={20} height={20} /> <div className="d-inline-block select-avatar-text-style-14">{option.text}</div>
                                </div>
                            )
                        }
                        return (
                            <div className='d-flex'>
                                <img className="d-flex align-items-center select-avatar-style-14 mr-2" src={Placeholder} width={20} height={20} /> <div className="d-inline-block select-avatar-text-style-14">{option.text}</div>
                            </div>
                        )
                    }
                    return option?.text || "-"
                }}
                isOptionSelected={getIsSelected}
                placeholder={rest?.placeholder || `${t("common.select")}...`}
                components={
                    fillArrow
                        ?
                        { DropdownIndicator }
                        :
                        hideIndicator
                            ?
                            { IndicatorSeparator: () => null }
                            :
                            {}
                }
                filterOption={createFilter(filterConfig)}
                {...rest}
            />
        </>
    );
};

export default Select;
