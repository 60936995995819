import React from "react";

export default function ArrowRightTailedIcon({ size=16 ,stroke = "black" }) {
  return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.62 3.95312L13.6667 7.99979L9.62 12.0465" stroke="#F9FAFB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M2.33337 8H13.5534" stroke="#F9FAFB" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
  );
}
