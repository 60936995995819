import { React, useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import LayoutFullpage from 'layout/LayoutFullpage';
import HtmlHead from 'components/html-head/HtmlHead';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import LockOpenOutlinedIcon from '@material-ui/icons/LockOpenOutlined';
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone';
import { Badge, Button, Card, Col, Row } from 'react-bootstrap';
import Glide from 'components/carousel/Glide';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { setColor } from "settings/settingsSlice";
import { changeLang } from "lang/langSlice";
import GoogleIcon from "cs-line-icons/custom/GoogleIcon";
import MnFlagIcon from "cs-line-icons/custom/MnFlagIcon";
import EnFlagIcon from "cs-line-icons/custom/EnFlagIcon";
import Select from "modules/Form/Select";
import { setAuth, setPersonInfo, setLoading, setSchools, setSelectedSchool } from '../../utils/redux/action';
import showMessage from "../../modules/message";
import RecoverPassword from './RecoverPassword';
import RegistrationModal from './Registration';
import ConfirmationModal from './Confirmation';
import ForgotModal from './forgotModal';
import { useGoogleLogin, googleLogout } from '@react-oauth/google';
import { auth, studentAuth } from '../../utils/fetchRequest/Urls';
import { fetchRequest, fetchRequestNoToken } from '../../utils/fetchRequest/index'
import { makeStyles } from '@mui/styles';
import { getWindowDimensions } from 'utils/utils';

const useStyles = makeStyles({
    inputButtonStyle: {
        display: 'flex',
        border: '1px solid var(--secondary)',
        width: '100%',
        textAlign: 'left',
        height: 44,
        borderRadius: 12,
        letterSpacing: '-0.5px',
        color: 'var(--dark-text) !important',
        background: 'white !important',
        alignItems: 'center',
        '& svg': {
            position: 'relative',
            right: 8
        },
    },
    loginButton: {
        borderRadius: 10,
        height: 36,
        letterSpacing: 0.2
    },
    forgotButton: {
        backgroundColor: 'white !important',
        color: '#FF4949 !important',
        padding: 0,
        height: 15,
        '&:hover': {
            backgroundColor: 'white',
            color: '#FF4949 !important',
        },
        '&:focus, &.focus': {
            backgroundColor: 'white',
            color: '#FF4949 !important',
        }
    }
});

const Relative = styled.div`
    position: relative;
    > svg {
      position: absolute;
      top: 9px;
      left: 12px;
      color: rgba(60, 53, 143, 0.5);
      z-index: 1;
    }
}`;

const Input = styled.input`
    min-height: 44px;
    padding-left: 40px;
    border-radius: 4px;
}`;

const CheckboxDiv = styled.div`
    .form-check-input {
        border-width: 2px;
        border-color: var(---dark-text-rgba);
        width: 18px;
        height: 18px
    }
    input {
        border-radius: 4px;
    } 
}`;

const Login = () => {
    const classes = useStyles();
    const { height } = getWindowDimensions()

    const handleResize = () => {
        const image = document.getElementById('image')
        const copyright = document.getElementById('copyright')
        const privacy = document.getElementById('privacy')
        // image.style.height = document.getElementById('container').clientHeight + 'px'
        copyright.style.top = document.getElementById('container').clientHeight - 180 + 'px'
        privacy.style.top = document.getElementById('container').clientHeight - 40 + 'px'
    }

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    })

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    // const { selectedSchool } = useSelector(state => state.schoolData);
    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [showRegistrationModal, setShowRegistrationModal] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showForgotModal, setShowForgotModal] = useState(false)
    const [languageOptions, setLanguageOptions] = useState([
        {
            value: 'mn',
            text: 'MN',
            isSvg: true,
            avatar: <MnFlagIcon />
        },
        {
            value: 'en',
            text: 'EN',
            isSvg: true,
            avatar: <EnFlagIcon />
        }
    ])
    const { currentLang } = useSelector((state) => state.lang);
    const title = t('auth.login');
    const description = t('auth.loginPage');

    const [accessToken, setAccessToken] = useState(null)

    const validationSchema = Yup.object().shape({
        username: Yup.string().required(t('errorMessage.enterPhoneNumber')),
        password: Yup.string().min(4, t('errorMessage.enterAtLeastFourCharacter')).required(t('errorMessage.enterPassword')),
    });

    const googleLogin = useGoogleLogin({
        onSuccess: codeRes => {
            dispatch(setLoading(true))
            fetchRequest(studentAuth, 'POST', { code: codeRes.code, googleRes: JSON.stringify(codeRes) })
                .then(res => {
                    if (res?.success) {
                        const { token, eToken, lmsToken, schools } = res;
                        dispatch(setAuth(Object.assign({ token }, { eToken }, { lmsToken })));
                        dispatch(setPersonInfo(res?.student));
                        dispatch(setColor("student-blue"));
                        if (schools && schools.length > 0) {
                            dispatch(setSelectedSchool(schools[0]))
                        } else {
                            dispatch(setSelectedSchool(null))
                        }
                        dispatch(setSchools(schools))
                        dispatch(setLoading(false))
                        history.push('/home/index')
                    } else {
                        dispatch(setLoading(false))
                        googleLogout()
                        showMessage(res?.message, false)
                    }
                })
                .catch(e => {
                    dispatch(setLoading(false))
                    googleLogout()
                })
        },
        onError: err => {
            googleLogout()
        },
        flow: 'auth-code',
        // scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/classroom.courses https://www.googleapis.com/auth/classroom.announcements https://www.googleapis.com/auth/classroom.rosters https://www.googleapis.com/auth/classroom.coursework.students https://www.googleapis.com/auth/calendar',
        scope: 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/classroom.courses.readonly https://www.googleapis.com/auth/classroom.rosters.readonly https://www.googleapis.com/auth/classroom.announcements',
        prompt: 'consent'
    })

    const fetchLoginUrl = () => {
        fetchRequestNoToken('google/refresh', 'POST')
            .then(res => {
                window.open(res.data.auth)
            })
            .catch(e => {
                //
            })
    }

    useEffect(() => {
        i18n.changeLanguage(currentLang.locale);
    }, []);

    const initialValues = {
        username: '',
        password: '',
        remember: false,
        lang: currentLang.code,
    };

    const onSubmit = (values) => {
        dispatch(setLoading(true))
        fetchRequest(auth, 'POST', values)
            .then((res) => {
                if (res.success) {
                    const { token, eToken, lmsToken, person, schools = [], user } = res;
                    // if (schools && schools.length > 0) {
                    dispatch(setAuth(Object.assign({ token }, { eToken }, {lmsToken})));
                    dispatch(setPersonInfo(person));
                    dispatch(setColor("student-blue"));
                    if (schools && schools.length > 0) {
                        dispatch(setSelectedSchool(schools[0]))
                    } else {
                        dispatch(setSelectedSchool(null))
                    }
                    dispatch(setSchools(schools))
                    history.push('/')
                    // } else {
                    //     showMessage(t('errorMessage.schoolNotFound'))
                    // }                    
                }
                else {
                    showMessage(res.message, res.success)
                }
                dispatch(setLoading(false))
            }).catch(() => {
                showMessage(t('errorMessage.title'))
                dispatch(setLoading(false))
            })
    };

    const handleForgotPassword = () => {
        setShowForgotPassword(true);
    }

    const onForgotPasswordClick = () => {

    }

    const formik = useFormik({ initialValues, validationSchema, onSubmit });
    const { handleSubmit, handleChange, values, touched, errors } = formik;

    const handleSelectChange = value => {
        values.lang = value

        dispatch(changeLang(value));
        i18n.changeLanguage(value);
    }

    const onHandlerConfirmation = () => {
        setShowConfirmModal(true)
    }

    const onHandlerRegistration = () => {
        setShowRegistrationModal(true)
    }

    const onHandlerForgotModal = () => {
        setShowForgotModal(true)
    }

    const leftSide = (
        <div id='container' className="w-100 min-h-100 h-100 bg-foreground d-flex flex-column py-5">
            <div className='min-h-100'>
                <div className='d-flex justify-content-center'>
                    <div className="sw-lg-70">
                        <div className='d-flex flex-row-reverse mt-1'>
                            <div style={{ width: 100 }}>
                                <Select
                                    clearable={false}
                                    avatarEntity14
                                    hideIndicator
                                    avatarEntity14Style={{
                                        border: '1px solid #0000001F',
                                        background: 'white',
                                    }}
                                    avatarEntity14ValueContainerStyle={{
                                        padding: '2px 0px'
                                    }}
                                    options={languageOptions || []}
                                    value={currentLang.locale || null}
                                    onChange={(value) => handleSelectChange(value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='h-95 d-flex justify-content-center align-items-center'>
                    <div className="sw-lg-70">
                        <Card className='login-carousel-style'>
                            <Card.Body>
                                <Glide
                                    onlyDots={true}
                                    options={{
                                        gap: 0,
                                        perView: 1,
                                    }}
                                >
                                    {[1, 2, 3].map((i) => (
                                        <Glide.Item key={`single.${i}`}>
                                            <div className='d-flex justify-content-center align-items-center' style={{height: height / 1.5}}></div>
                                        </Glide.Item>
                                    ))}
                                </Glide>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </div>
    );

    const rightSide = (
        <div id='container' className="min-h-100 h-100 bg-foreground d-flex flex-column py-5">
            <div className='min-h-100'>
                <div className='d-flex justify-content-center'>
                    <div className="sw-lg-70">
                        <NavLink to="/">
                            <img src="/img/logo/estudent-dark.png" alt="eschool logo" height={65} style={{ position: 'relative', right: 10 }} />
                        </NavLink>
                    </div>
                </div>

                <div className='h-95 d-flex justify-content-center align-items-center'>
                    <div className="sw-lg-70">
                        <div className='fs-40 pinnacle-demi-bold text-black mb-2'>{t('auth.login')}</div>
                        <div className='fs-14 text-muted-darker font-weight-300 mb-6'>{t('auth.seeAgain')}</div>
                        <div>
                            <form id="loginForm" className="tooltip-end-bottom" onSubmit={handleSubmit}>
                                <button
                                    className={'btn-icon hover-outline mb-4 btn btn-background-alternate fs-12 btn-icon-24 ' + classes.inputButtonStyle}
                                    onClick={() => {
                                        googleLogin()
                                    }}
                                >
                                    <GoogleIcon />
                                    {t('errorMessage.enterEmail')}
                                </button>
                                {/* <Relative className="mb-3 form-group tooltip-end-bottom font-pinnacle fs-14">
                                    <GoogleIcon />
                                    <Input
                                        onWheel={e => e?.target?.blur()}
                                        className='form-control font-pinnacle fs-14 text-medium' 
                                        type="text" 
                                        name="username" 
                                        placeholder={t('common.phoneNumber')} 
                                        value={values.username} 
                                        onChange={handleChange} 
                                    />
                                    {errors.username && touched.username && <div className="d-block invalid-tooltip">{errors.username}</div>}
                                </Relative>
                                <Relative className="mb-3 form-group tooltip-end-bottom font-pinnacle fs-14">
                                    <LockOpenOutlinedIcon />
                                    <Input className='form-control font-pinnacle fs-14 text-medium' type="password" name="password" placeholder={t('auth.password')} value={values.password} onChange={handleChange} />
                                    {errors.password && touched.password && <div className="d-block invalid-tooltip">{errors.password}</div>}
                                </Relative> */}
                                <div className='d-flex justify-content-between mb-3'>
                                    <div>
                                        <CheckboxDiv className="form-check">
                                            <input className="form-check-input" id='rememberMe' type="checkbox" value={values.remember} onChange={handleChange} />
                                            <label className="form-check-label font-mulish fs-12 text-black" htmlFor="rememberMe">
                                                {t('auth.rememberMe')}
                                            </label>
                                        </CheckboxDiv>
                                    </div>
                                    <div>
                                        <button className={'btn btn-background-alternate ' + classes.forgotButton} onClick={() => onHandlerForgotModal()}>
                                            {t('auth.forgotYourPassword')}
                                        </button>
                                    </div>
                                </div>

                                {/* <button type="submit" className='btn btn-primary w-100 fw-bold pinnacle-bold' style={{ borderRadius: '8px', height: '40px' }}>
                                    {t('auth.login').toUpperCase()}
                                </button>
                                <button type="button" className='btn btn-outline-primary w-100 fw-bold pinnacle-bold mt-3' style={{ borderRadius: '8px', height: '40px' }} onClick={onHandlerRegistration}>
                                    {t('auth.signUp').toUpperCase()}
                                </button>
                                <button type="button" className='btn btn-empty w-100 fw-bold pinnacle-bold mt-3 text-decoration-underline ' style={{ borderRadius: '8px', height: '40px' }} onClick={onHandlerConfirmation}>
                                    {t('auth.userConfirm').toUpperCase()}
                                </button>
                                <button type="submit" className='btn btn-info w-100 fw-bold pinnacle-bold' style={{ borderRadius: '8px', height: '40px' }}>
                                            {t('auth.login').toUpperCase()}
                                    </button> */}
                                <div className='d-grid gap-3 mt-3'>
                                    <button
                                        type="button"
                                        className={"btn btn-secondary fs-14 font-weight-400 " + classes.loginButton}
                                        onClick={() => {
                                            googleLogin()
                                        }}
                                    >
                                        {t('auth.login')}
                                    </button>
                                    {/* <button type="button" className="btn btn-outline-light pinnacle-bold text-black d-flex justify-content-center align-items-center" style={{ borderRadius: '8px', height: '36px', fontSize: '.7em' }}>
                                                <Img src='/img/auth/microsoftSSO.png' alt="microsoft logo" />
                                                {t('auth.loginMicrosoft')}
                                        </button> */}
                                </div>
                                <div className="d-flex align-items-center flex-column justify-content-between mb-5" style={{ marginTop: '150px' }}>
                                    {/* <button type="button" className='login-recover-button btn btn-outline-danger font-pinnacle fs-14' style={{ borderRadius: '8px' }} onClick={handleForgotPassword}>
                                        {t('auth.clickHereIfYouForgotYourPassword')}
                                    </button> */}
                                </div>
                                <div className='d-flex align-items-center flex-column justify-content-between'>
                                    <p id='copyright' className='position-absolute login-copyright'>&copy; {new Date().getFullYear()} {t('auth.copyright')}</p>
                                </div>
                                <div className='d-flex align-items-center flex-column justify-content-between'>
                                    <a id='privacy' className='position-absolute login-privacy' href="https://estudent.mn/privacy.html" target="_blank">{t('auth.privacy')}</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            <HtmlHead title={title} description={description} />
            <LayoutFullpage left={leftSide} right={rightSide} />
            {
                showForgotPassword &&
                <RecoverPassword
                    show={showForgotPassword}
                    setShow={setShowForgotPassword}
                    onSubmit={onForgotPasswordClick}
                />
            }
            {
                showRegistrationModal &&
                <RegistrationModal
                    show={showRegistrationModal}
                    setShow={setShowRegistrationModal}
                />
            }
            {
                showConfirmModal &&
                <ConfirmationModal
                    show={showConfirmModal}
                    setShow={setShowConfirmModal}
                />
            }
            {
                showForgotModal &&
                <ForgotModal
                    show={showForgotModal}
                    setShow={setShowConfirmModal}
                    onClose={() => setShowForgotModal(false)}
                />
            }
        </>
    );
};

export default Login;