import React from 'react';

export default function CircleXIcon({
	stroke = '#9CA3AF',
	fill = '#D7DADF',
	width = 24,
	height = 24,
}) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill={fill}
			
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		>
			<circle cx="12" cy="12" r="10" />
			<path d="m15 9-6 6" stroke={stroke}/>
			<path d="m9 9 6 6" stroke={stroke}/>
		</svg>
	);
}
