import React from "react";

export default function BellIcon1({ stroke = "#3c358f" }) {
    return (
        <svg width="22" height="22" stroke={stroke} viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Rounded" transform="translate(-408.000000, -4100.000000)">
                    <g id="Social" transform="translate(100.000000, 4044.000000)">
                        <g id="-Round-/-Social-/-notifications_active" transform="translate(306.000000, 54.000000)">
                            <g>
                                <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                                <path d="M18,16 L18,11 C18,7.93 16.36,5.36 13.5,4.68 L13.5,4 C13.5,3.17 12.82,2.5 11.99,2.5 C11.16,2.5 10.5,3.17 10.5,4 L10.5,4.68 C7.63,5.36 6,7.92 6,11 L6,16 L4.7,17.29 C4.07,17.92 4.51,19 5.4,19 L18.57,19 C19.46,19 19.91,17.92 19.28,17.29 L18,16 Z M11.99,22 C13.09,22 13.99,21.1 13.99,20 L9.99,20 C9.99,21.1 10.88,22 11.99,22 Z M6.77,4.73 C7.19,4.35 7.2,3.7 6.8,3.3 C6.42,2.92 5.8,2.91 5.41,3.28 C3.7,4.84 2.52,6.96 2.14,9.34 C2.05,9.95 2.52,10.5 3.14,10.5 C3.62,10.5 4.04,10.15 4.12,9.67 C4.42,7.73 5.38,6 6.77,4.73 Z M18.6,3.28 C18.2,2.91 17.58,2.92 17.2,3.3 C16.8,3.7 16.82,4.34 17.23,4.72 C18.61,5.99 19.58,7.72 19.88,9.66 C19.95,10.14 20.37,10.49 20.86,10.49 C21.47,10.49 21.95,9.94 21.85,9.33 C21.47,6.96 20.3,4.85 18.6,3.28 Z" id="🔹-Icon-Color" fill={stroke}></path>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}