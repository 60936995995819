import React, { useRef, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';

const ForgotModal = ({
    show,
    setShow,
    onClose
}) => {
    const { t } = useTranslation();
    const formRef = useRef();
    const history = useHistory();
    const dispatch = useDispatch();

    return (
        <Modal
            centered
            show={show}
            onHide={onClose}
            size='lg'
            className='forgot-password-modal-style'
        >
            <Modal.Header style={{ padding: '1rem 2rem 0 2rem', border: 'unset' }}>
                <Modal.Title className='fs-18 w-100'>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>
                            {t('auth.isForgotPassword')}
                        </div>
                        <div>
                            <button className='btn-icon btn-icon-only btn btn-secondary btn-sm btn-sm-25 rounded-circle' onClick={onClose}>
                                <span style={{ position: 'relative', bottom: 1 }}>
                                    <CloseIcon />
                                </span>
                            </button>
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='px-0 pt-4'>
                <div className="d-flex justify-content-center fs-18 mb-1">
                    {t('auth.contactYourManager')}
                </div>
                <div className='text-center'>
                    <img src='../img/student.png' width={200} height={200} />
                </div>
                {/* <div style={{ position: 'absolute', right: -1, bottom: 140 }}>
                    <svg width="50" height="193" viewBox="0 0 50 193" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_f_8808_21647)">
                            <path d="M73.5155 64.4748C73.5155 64.4748 105.066 -6.6381 119.078 32.4284C133.089 71.4948 184.893 98.1017 167.808 101.711C150.723 105.32 147.108 119.988 133.867 130.585C118.276 143.063 103.607 171.795 92.4722 172.374C81.337 172.953 -2.52779 135.374 26.9722 66.8737C56.4723 -1.62628 44.4723 57.8738 73.5155 64.4748Z" fill="url(#paint0_linear_8808_21647)" />
                        </g>
                        <defs>
                            <filter id="filter0_f_8808_21647" x="0.720398" y="0.708008" width="190.505" height="191.673" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                                <feGaussianBlur stdDeviation="10" result="effect1_foregroundBlur_8808_21647" />
                            </filter>
                            <linearGradient id="paint0_linear_8808_21647" x1="11.2412" y1="90.2228" x2="134.348" y2="47.9851" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#7E5BCC" />
                                <stop offset="1" stop-color="#6A46BA" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div style={{ backgroundImage: 'linear-gradient(to right, #7E5BCC, yellow)' }}>
                </div> */}
            </Modal.Body>
        </Modal >
    );
};




export default ForgotModal;